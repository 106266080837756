.pg-game-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  overflow: hidden;
}

.ui.inverted.menu {
  margin-top: 0;
}