div.splitter {
  display: flex;
  margin: 0 64px;
}

body {
  background-color: #e8e8e8 !important;
}

.ui.disabled.input {
  opacity: 1 !important;
}

a.ui.card,
div.ui.card,
div.ui.segment {
  transition: opacity 0.15s linear, scale 0.15s linear;
  opacity: 1;
  &.is_map {
    opacity: 0;
    pointer-events: none;
  }
}

.strike {
  text-decoration: line-through;
}

.Wa {
  color: #2185d0 !important;
}
.Cu {
  color: #f26202 !important;
}
.Fe {
  color: #a333c8 !important;
}
.Ag {
  color: #767676 !important;
}
.Au {
  color: #fbbd08 !important;
}
.Oil {
  color: #b5cc18 !important;
}
.Dia {
  color: #009c95 !important;
}
.Ti {
  color: #e61a8d !important;
}
.Pt {
  color: #6435c9 !important;
}
.U {
  color: #d01919 !important;
}

.Wa-bg {
  background-color: #2185d0 !important;
  color: white;
}
.Cu-bg {
  background-color: #f26202 !important;
  color: white;
}
.Fe-bg {
  background-color: #a333c8 !important;
  color: white;
}
.Ag-bg {
  background-color: #767676 !important;
  color: white;
}
.Au-bg {
  background-color: #fbbd08 !important;
  color: white;
}
.Oil-bg {
  background-color: #b5cc18 !important;
  color: white;
}
.Dia-bg {
  background-color: #009c95 !important;
  color: white;
}
.Ti-bg {
  background-color: #e61a8d !important;
  color: white;
}
.Pt-bg {
  background-color: #6435c9 !important;
  color: white;
}
.U-bg {
  background-color: #d01919 !important;
  color: white;
}

.bold {
  font-weight: bold;
}