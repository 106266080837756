.timer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;

  .timer {
    letter-spacing: 2px;
    border-radius: 4px;
    font-size: 24px;
    padding: 8px 14px;
    box-shadow: 0 0 4px 1px rgba(255,255,255,0.2);
  }
}

.timer-charges {
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}