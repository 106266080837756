div.pr-wrapper {
  font-size: 12px;

  .drop-shadow {
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.3);
  }

  div.pr-header {
    display: flex;
    background-color: rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);

    &.details {
      padding: 6px 0;
      color: black;
      font-weight: bold;
      font-size: 14px;
    }
    
    div.pr-hcolumn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      &.left {
        justify-content: flex-start;
      }

      &.right {
        justify-content: flex-end;
      }
    }
  }

  div.pr-row {
    display: flex;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.1s linear;

    &.current {
      background-color: #cecece !important;
    }

    &.locked {
      background-color: #ffeb3b;
    }

    &.upgraded {
      background-color: lightgreen !important;
    }

    &.downgraded {
      background-color: lightcoral !important;
    }

    &:hover {
      background-color: rgba(0,0,0,0.05);
    }

    .pr-row-chunk-details {
      width: 100%;
      display: flex;
    }

    .pr-row-chunk-loan {
      display: flex;
      width: 100%;
      transition: background-color 0.1s linear;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      &.loaned {
        background-color: #21ba45 !important;
        color: white !important;
      }

      &:hover {
        background-color: rgba(0,0,0,0.05);
      }
    }

    div.pr-column {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.left {
        justify-content: flex-start;
      }

      &.right {
        justify-content: flex-end;
      }
    }
  }

  .strength-format {
    margin-top: 3px;
  }

  .pr-planet-attributes {
    display: flex;
    justify-content: space-between;

    & .pr-pa-item {

      .mine {
        text-align: right;
        background-color: white;
        cursor: pointer;
        transition: background-color 0.1s linear;
        padding: 1.5px 12px;
        margin: 1.5px 0 1.5px 3px;

        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
  
        &:hover {
          background-color: rgba(0,0,0,0.1);
        }
      }

      .disaster {
        background-color: white;
        cursor: pointer;
        transition: background-color 0.1s linear;
        padding: 1.5px 6px;
        margin: 1.5px 3px 1.5px 0;

        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
  
        &:hover {
          background-color: rgba(0,0,0,0.1);
        }
      }
    }
  }
}