.PSL-splitter {
  margin-top: 8px;
  display: flex;
  width: 100%;
  &>:nth-child(1) {
    width: 100%;
  }
  &>:nth-child(2) {
    text-align: left;
    white-space: nowrap;
  }
}

.psl-disconnected {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  padding: 16px;

  &>div {
    background-color: rgb(255, 0, 0);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
    font-weight: 800;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    user-select: none;
  }

  &.disconnected {
    opacity: 0.5;
    pointer-events: all;
  }
}