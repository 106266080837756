div.cash-fader {
  position: relative;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease-in;
  color: #21ba45;
  transform: scale(0.9);

  &.cash-fader-visible {
    left: 16px;
    opacity: 1;
    transform: scale(1.05);
    transition: all 0.05s linear !important;
  }

  &.negative {
    color: #b21e1e;
  }
}