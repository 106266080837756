.loan-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 16px;

  .loan-header {
    display: flex;
    width: 80%;
  }

  .loan-row {
    width: 80%;
    display: flex;
    cursor: pointer;
    background-color: transparent;
    border-radius: 6px;
    transition: background-color 0.1s linear;

    &.active {
      background-color: #2185d0 !important;
      color: white !important;
      margin-bottom: 2px;
    }

    &:hover {
      background-color: rgba(0,0,0,0.1);
    }
  }

  .loan-column {
    flex: 1;
    display: flex;
    justify-content: left;
    margin-left: 8%;
  }
}