div.menu {
  & div.item.resource-button {
    padding: 0 6px;
    border: none;
    &:before {
      background: transparent !important;
    }
    div.ui.button {
      height: 28px;
    
      & button {
        padding: 3px 8px;
      }
    }
  }
}