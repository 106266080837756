.q-wrapper {
  display: flex;
  align-items: left;
  justify-content: left;
  cursor: pointer;
  overflow: "scroll";

  .quest {
    letter-spacing: 2px;
    border-radius: 4px;
    font-size: 24px;
    padding: 8px 14px;
    margin: 0 24px;
    color: rgb(37, 4, 4);
    box-shadow: 0 0 4px 1px rgba(255,255,255,0.2);
  }

}

.q-breakdown {
  width: 650px;
  border: 1px solid black;
  border-radius: 4px;
  overflow-y: scroll; 
  max-height: 500px;
  

  .q-header {
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 6px 0;
    background-color: green;
    color: white;
    font-size: 18px;
  }

  .left .q-content .columns:nth-of-type(2) {
    justify-content: flex-start;
  }

  .left .q-content .columns:nth-of-type(4) {
    justify-content: flex-start;
  }

  .left .q-content .columns:nth-of-type(3) {
    justify-content: flex-start;
  }

  .q-content {
    display: flex;
    height: min-content;
    overflow: "scroll";

   

    .tall {
      height: 24px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(0,0,0,0.2);
    }

    .columns {
      display: flex;
      align-items: left;
      justify-content: left;
      text-align: left;
      width: 100%;
      padding-left: 8px;
      &>.ui.button {
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .left {
        justify-content: left;
      }

      .padded {
        width: 1% !important;
        height: 10% !important;
      }
      
      .indented {
        padding-left: 24px;
      }

      .bold {
        font-weight: bold;
      }

      &:first-of-type {
        justify-content: center;
        width: 60%;
      }
      
      &:not(:last-of-type) {
        border-right: 1px solid rgba(0,0,0,0.2);
      }
    }

    .minicolumn {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 25%;
      &>.ui.button {
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .padded {
        width: 1% !important;
        height: 10% !important;
      }
    
      &:not(:last-of-type) {
        border-right: 1px solid rgba(0,0,0,0.2);
      }
    }

  }
}