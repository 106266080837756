.pr-cash {
  .pr-border-bottom {
    border-bottom: 1px solid rgba(0,0,0,0.1) !important;
    margin-bottom: 12px !important;
  }

  .ui.statistic {
    padding: 12px;
  }

  .pr-upgrade {
    border: 1px solid rgba(0,0,0,0.2);
    padding: 12px;
    border-radius: 6px;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1);
  }
}
