.cr-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .rating {
    letter-spacing: 2px;
    border-radius: 4px;
    font-size: 24px;
    padding: 8px 14px;
    margin: 0 24px;
    color: black;
    box-shadow: 0 0 4px 1px rgba(255,255,255,0.2);
  }
}

.cr-breakdown {
  width: 650px;
  border: 1px solid black;
  border-radius: 4px;
  overflow: hidden;

  .cr-header {
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 6px 0;
    background-color: green;
    color: white;
    font-size: 18px;
  }

  .left .cr-content .columns:nth-of-type(2) {
    justify-content: flex-start;
  }

  .left .cr-content .columns:nth-of-type(4) {
    justify-content: flex-start;
  }

  .left .cr-content .columns:nth-of-type(3) {
    justify-content: flex-start;
  }

  .cr-content {
    display: flex;
    height: 27px;

   

    .tall {
      height: 48px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(0,0,0,0.2);
    }

    .columns {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-left: 8px;
      &>.ui.button {
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .padded {
        width: 1% !important;
        height: 10% !important;
      }
      
      .indented {
        padding-left: 24px;
      }

      .bold {
        font-weight: bold;
      }

      &:first-of-type {
        justify-content: flex-start;
      }

      &:not(:last-of-type) {
        border-right: 1px solid rgba(0,0,0,0.2);
      }
    }
  }
}