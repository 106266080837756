.gameboard-container {
  position: absolute;
  top: calc(40px + 70px);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  color: white;
  font-size: 3rem;
  &.edit {
    top: 0;
  }

  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
}