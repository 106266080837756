@keyframes level-up {
  50% {
    box-shadow: 0 0 16px rgb(12, 235, 4);
  }
}

div.leveling-up {
  border-radius: 4px;
  height: fit-content;
  padding-bottom: -16px;
  animation: level-up 1.0s linear infinite;
}
